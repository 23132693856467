import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import chunk from 'lodash.chunk'
import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const IMAGES_QUERY = graphql`
  {
    drevo: allFile(filter: { relativePath: { glob: "vzorky/drevo/*" } }) {
      ...FluidImageData
    }
    farebne: allFile(filter: { relativePath: { glob: "vzorky/farebne/*" } }) {
      ...FluidImageData
    }
    zrkadla: allFile(filter: { relativePath: { glob: "vzorky/zrkadla/*" } }) {
      ...FluidImageData
    }
  }
`

const imagesRenderFn = edges =>
  chunk(edges, 3).map((set, index) => (
    <div key={index} className="columns">
      {set.map(edge => (
        <div key={edge.node.id} className="column is-4 no-side-padding-mobile">
          <figure className="image">
            <span className="tag" style={{ borderRadius: `5px 5px 0px 0px` }}>
              {edge.node.name}
            </span>
            <Img
              fluid={edge.node.childImageSharp.fluid}
              title={edge.node.name}
              alt={edge.node.name}
            />
          </figure>
        </div>
      ))}
    </div>
  ))

const renderFn = data => (
  <>
    <h1 className="title">Vzorkovník</h1>
    <h2 className="subtitle">Množstvo dekorov z ktorých si môžete vybrať</h2>
    <div className="box">
      <h2 className="subtitle">Drevo</h2>
      {imagesRenderFn(data.drevo.edges)}
    </div>

    <div className="box">
      <h2 className="subtitle">Farebné</h2>
      {imagesRenderFn(data.farebne.edges)}
    </div>

    <div className="box">
      <h2 className="subtitle">Zrkadlá</h2>
      {imagesRenderFn(data.zrkadla.edges)}
    </div>
  </>
)

const Vzorkovnik = () => (
  <Layout>
    <SEO
      title="Vzorkovník"
      description="Vzorkovník všetkých dostupných materiálov s ktorými pracujeme"
    />
    <StaticQuery query={IMAGES_QUERY} render={renderFn} />
  </Layout>
)

export default Vzorkovnik
